import {NavigationBar, useSelectedCompetition} from "src/components/Navigation";
import {ContentWrapper, PageWrapper} from "src/components/ContentWrapper/ContentWrapper";
import {auth, competitionDocumentRef} from "src/config/firebase";
import {useDocumentData} from "react-firebase-hooks/firestore";
import {
    alpha,
    Box,
    Card,
    CardActionArea,
    CardContent,
    FormControl,
    Grid,
    NativeSelect,
    Stack,
    Typography
} from "@mui/material";
import Spacer from "src/components/Spacer/Spacer";
import {useAuthState} from "react-firebase-hooks/auth";
import {groupBy, sort} from "src/utils/collectionUtils";
import React, {useEffect} from "react";
import {Competition, CompetitionEvent, CompetitionMatch, Outcome, Player, Team} from "src/types/competitions";
import {useNavigate} from "react-router-dom";
import {isNot, match as fpMatch, unionOf} from 'src/utils/fpUtils'
import {usePrevious} from "src/utils/hookUtils";
import {useLocalStorage} from "src/hooks/useLocalStorage";

export const TeamColours: Record<Team, string> = {
    [Team.USA]: '#C81414',
    [Team.EUROPE]: '#003C82',
}

export const OutcomeColours: Record<Outcome, string> = {
    [Outcome.TEAM_USA]: '#C81414',
    [Outcome.TEAM_EUROPE]: '#003C82',
    [Outcome.TIE]: '#f0f0f0',
    [Outcome.UNKNOWN]: '#f3f0f0',
}

const TeamCard = ({team, players, align, win}: {
    team: Team,
    players: Player[],
    align: 'left' | 'right',
    win: boolean
}) => (
    <Grid item xs={3} sx={{
        color: win ? 'white' : 'inherit',
        background: win ? TeamColours[team] : 'transparent',
        position: 'relative',
        borderLeft: align === 'left' ? `5px solid ${TeamColours[team]}` : 'none',
        borderRight: align === 'right' ? `5px solid ${TeamColours[team]}` : 'none',
        '&:after': win ? {
            background: 'transparent',
            borderBottom: '100px solid transparent',
            borderLeft: align === 'left' ? `40px solid ${TeamColours[team]}` : 'none',
            borderRight: align === 'right' ? `40px solid ${TeamColours[team]}` : 'none',
            borderTop: '100px solid transparent',
            content: '""',
            height: '0',
            position: 'absolute',
            left: align === 'left' ? '98%' : 'initial',
            right: align === 'right' ? '98%' : 'initial',
            top: '50%',
            transform: 'translateY(-50%)',
        } : {}
    }}>
        {players.map(player =>
            <Box key={player.user_id}
                 sx={{
                     p: [2, 1],
                     textAlign: align,
                 }}>
                {
                    player.name.split(' ').map((part, index) =>
                        index === 0 ?
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontStyle: 'italic',
                                    m: 0,
                                    lineHeight: '1',
                                    fontSize: '0.75rem'
                                }}>{part}</Typography>
                            :
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                    textTransform: 'uppercase',
                                    fontSize: '0.95em'
                                }}>{part}</Typography>
                    )
                }
            </Box>
        )}
    </Grid>
)

const MatchCard = ({competition, event, match, europePlayers, usaPlayers}: {
    competition: Competition,
    event: CompetitionEvent,
    match: CompetitionMatch,
    europePlayers: Player[],
    usaPlayers: Player[]
}) => {
    const navigate = useNavigate();
    const usaPoints = match.results.filter(result => result.winner.toString() === Team.USA).length;
    const europePoints = match.results.filter(result => result.winner.toString() === Team.EUROPE).length;
    const matchNotStarted = match.results.every(result => result.winner === Outcome.UNKNOWN)
    const matchFinished = match.results.every(result => result.winner !== Outcome.UNKNOWN)
    const result = matchNotStarted ?
        Outcome.UNKNOWN :
        (usaPoints > europePoints ?
                Outcome.TEAM_USA :
                (europePoints > usaPoints ?
                    Outcome.TEAM_EUROPE :
                    Outcome.TIE)
        )

    const scoreComponent = fpMatch({matchNotStarted, matchFinished, result})
        .with({matchNotStarted: true}, () => (
            <Typography
                sx={{
                    fontSize: '2rem',
                    fontWeight: 'bold',
                    color: OutcomeColours[result]
                }}
                component={"span"}>N/A</Typography>
        ))
        .with({matchFinished: true, result: isNot(unionOf(Outcome.TIE, Outcome.UNKNOWN))}, () => {
            const {sum, remainder} = match.results
                .reduce<{ stop: boolean, sum: number, remainder: number }>(
                    (props, res, index) => {
                        if (props.stop) return props;
                        const hole = index;
                        if (props.sum > (match.results.length - hole)) {
                            return {...props, stop: true, remainder: match.results.length - hole};
                        }
                        if (res.winner === Outcome.TIE) return props;
                        return {...props, sum: props.sum + (res.winner === result ? 1 : -1)};
                    }, {stop: false, sum: 0, remainder: 0});

            return (
                <>
                    <Typography
                        sx={{
                            fontSize: '3rem',
                            fontWeight: 'bold',
                            color: OutcomeColours[result]
                        }}
                        component={"span"}>{sum}</Typography>
                    {
                        <Typography
                            sx={{
                                fontSize: '1.25rem',
                                fontWeight: 'bold',
                                color: OutcomeColours[result]
                            }}
                            component={"span"}>{remainder > 0 ? `&${remainder}` : `UP`}</Typography>
                    }
                </>
            );
        })
        .with({matchFinished: false, result: isNot(unionOf(Outcome.TIE, Outcome.UNKNOWN))}, ({result}) => {
            const score = result === Outcome.TEAM_USA ? usaPoints - europePoints : europePoints - usaPoints;
            return (
                <>
                    <Typography
                        sx={{
                            fontSize: '3rem',
                            fontWeight: 'bold',
                            color: OutcomeColours[result]
                        }}
                        component={"span"}>{score}</Typography>
                    <Typography
                        sx={{
                            fontSize: '1.25rem',
                            fontWeight: 'bold',
                            color: OutcomeColours[result]
                        }}
                        component={"span"}>UP</Typography>
                </>
            );
        })
        .with({result: Outcome.TIE}, () => <Typography
            sx={{
                fontSize: '2rem',
                fontWeight: 'bold',
                color: OutcomeColours[result]
            }}
            component={"span"}>A/S</Typography>
        )
        .otherwise(() => null)

    return (
        <Card key={event.name} sx={{border: '0'}}>
            <CardActionArea
                onClick={() => navigate(`/competition/${competition.id}/event/${event.id}/match/${match.id}`)}>
                <CardContent sx={{p: 0, position: 'relative'}}>
                    <Grid container>
                        <TeamCard team={Team.EUROPE} players={europePlayers}
                                  win={true}
                                  align={'left'}/>

                        <Grid item xs={6}>
                            <Stack direction={"column"}
                                   alignItems={"center"}
                                   justifyContent={"space-evenly"}
                                   sx={{height: '100%', textAlign: 'center'}}>
                                <Typography variant={"body1"}
                                            fontStyle={'italic'}
                                            fontWeight={'bold'}
                                            fontSize={'.75rem'}>{match.name}</Typography>
                                <Stack direction={"row"} alignItems={"center"}>
                                    {scoreComponent}
                                </Stack>
                            </Stack>
                        </Grid>

                        <TeamCard team={Team.USA} players={usaPlayers}
                                  win={true}
                                  align={'right'}/>
                    </Grid>

                    <Stack direction={"row"}
                           sx={{background: 'white', position: 'relative', py: 1}}
                           justifyContent={"space-evenly"}>
                        {match.results.map((result, index) => (
                            <Box
                                key={`hole-${index}`}
                                sx={{
                                    background: OutcomeColours[result.winner],
                                    width: '18px',
                                    height: '18px',
                                    lineHeight: '19px',
                                    textAlign: 'center',
                                    fontSize: '.65rem',
                                    fontWeight: 'bold',
                                    borderRadius: '50%',
                                    color: 'white'
                                }}
                            >{index + 1}</Box>
                        ))}
                    </Stack>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

const ScoreTracker = ({competition}: { competition: Competition }) => {

    // NOTE: There's an option here to do the sum of total points won or the sum of matches won

    const pointsOnOffer = competition.events.reduce((sum, event) => {
        return sum + event.matches.length + event.matches.reduce((s, match) => {
            return s + match.additionalPoints[Team.EUROPE] + match.additionalPoints[Team.USA];
        }, 0);
    }, 0);
    const scores = competition.events.reduce((obj, event) => {
        return event.matches.reduce((newObj, match) => {
            const usaPoints = match.results.filter(result => result.winner.toString() === Team.USA).length;
            const europePoints = match.results.filter(result => result.winner.toString() === Team.EUROPE).length;
            const matchNotStarted = match.results.every(result => result.winner === Outcome.UNKNOWN)
            const matchFinished = match.results.every(result => result.winner !== Outcome.UNKNOWN)
            const scoresObj = {
                [Team.EUROPE]: {
                    ...newObj[Team.EUROPE],
                    confirmed: newObj[Team.EUROPE].confirmed + match.additionalPoints[Team.EUROPE]
                },
                [Team.USA]: {
                    ...newObj[Team.USA],
                    confirmed: newObj[Team.USA].confirmed + match.additionalPoints[Team.USA]
                },
            };
            const result = matchNotStarted ?
                Outcome.UNKNOWN :
                (usaPoints > europePoints ?
                        Outcome.TEAM_USA :
                        (europePoints > usaPoints ?
                            Outcome.TEAM_EUROPE :
                            Outcome.TIE)
                )

            return fpMatch({matchNotStarted, matchFinished, result})
                .with({matchNotStarted: true}, () => scoresObj)
                .with({
                    matchFinished: false,
                    result: unionOf(Outcome.TEAM_EUROPE, Outcome.TEAM_USA)
                }, ({result: winner}) => {
                    console.log('winner', winner, scoresObj, {
                        ...scoresObj[winner as unknown as Team],
                        projected: scoresObj[winner as unknown as Team].projected + 1
                    })
                    return ({
                        ...scoresObj,
                        [winner]: {
                            ...scoresObj[winner as unknown as Team],
                            projected: scoresObj[winner as unknown as Team].projected + 1
                            // projected: scoresObj[winner].projected + (winner === Outcome.TEAM_USA ? usaPoints - europePoints : europePoints - usaPoints)
                        }
                    });
                })
                .with({
                    matchFinished: false,
                    result: Outcome.TIE
                }, ({result: winner}) => ({
                    [Team.USA]: {
                        ...scoresObj[Team.USA],
                        projected: scoresObj[Team.USA].projected + 0.5
                    },
                    [Team.EUROPE]: {
                        ...scoresObj[Team.EUROPE],
                        projected: scoresObj[Team.EUROPE].projected + 0.5
                    }
                }))
                .with({
                    matchFinished: true,
                    result: unionOf(Outcome.TEAM_EUROPE, Outcome.TEAM_USA)
                }, ({result: winner}) => ({
                    ...scoresObj,
                    [winner]: {
                        ...scoresObj[winner as unknown as Team],
                        confirmed: scoresObj[winner as unknown as Team].confirmed + 1
                        // confirmed: scoresObj[winner].confirmed + (winner === Outcome.TEAM_USA ? usaPoints - europePoints : europePoints - usaPoints)
                    }
                }))
                .with({
                    matchFinished: true,
                    result: Outcome.TIE
                }, ({result: winner}) => ({
                    [Team.USA]: {
                        ...scoresObj[Team.USA],
                        confirmed: scoresObj[Team.USA].confirmed + 0.5
                    },
                    [Team.EUROPE]: {
                        ...scoresObj[Team.EUROPE],
                        confirmed: scoresObj[Team.EUROPE].confirmed + 0.5
                    }
                }))
                .otherwise(() => scoresObj)
        }, obj);
    }, {
        [Team.EUROPE]: {projected: 0, confirmed: 0},
        [Team.USA]: {projected: 0, confirmed: 0},
    })

    const europeConfirmed = {
        hole: Math.floor(scores[Team.EUROPE].confirmed),
        half: scores[Team.EUROPE].confirmed % 1 === 0.5
    };
    const usaConfirmed = {
        hole: Math.floor(scores[Team.USA].confirmed),
        half: scores[Team.USA].confirmed % 1 === 0.5
    };

    return (
        <Box sx={{
            width: '100%',
            height: '60px',
            position: 'relative',
            background: '#E0E0E0',
            '&:before': {
                background: '#000',
                borderRadius: '50%',
                content: '""',
                left: '0',
                margin: 'auto',
                position: 'absolute',
                right: '0',

                height: '5px',
                top: '-7px',
                width: '5px',
            },
            '&:after': {
                background: '#000',
                borderRadius: '50%',
                content: '""',
                left: '0',
                margin: 'auto',
                position: 'absolute',
                right: '0',

                bottom: '0',
                height: '60px',
                width: '1px',
            }
        }}>
            <Box sx={{
                fontSize: '3rem',
                fontWeight: '900',
                left: '10px',
                letterSpacing: '0',
                lineHeight: '48px',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: '2',
                color: 'white'
            }}>
                <span>{europeConfirmed.hole}</span>
                <span style={{
                    display: europeConfirmed.half ? 'inline-block' : 'none',
                    fontSize: "1rem",
                    fontWeight: "700",
                    letterSpacing: "-.71px",
                    lineHeight: "14px",
                    marginLeft: "5px",
                    position: "relative",
                    top: "-22px",
                }}>1/2</span>
            </Box>
            <Box sx={{
                position: 'absolute',
                width: `${scores[Team.EUROPE].confirmed / pointsOnOffer * 100}%`,
                height: '100%',
                left: 0,
                background: TeamColours[Team.EUROPE]
            }}/>
            <Box sx={{
                position: 'absolute',
                width: `${(scores[Team.EUROPE].confirmed + scores[Team.EUROPE].projected) / pointsOnOffer * 100}%`,
                height: '100%',
                left: 0,
                background: alpha(TeamColours[Team.EUROPE], 0.5)
            }}/>
            <Box sx={{
                position: 'absolute',
                width: `${scores[Team.USA].confirmed / pointsOnOffer * 100}%`,
                height: '100%',
                right: 0,
                background: TeamColours[Team.USA]
            }}/>
            <Box sx={{
                position: 'absolute',
                width: `${(scores[Team.USA].confirmed + scores[Team.USA].projected) / pointsOnOffer * 100}%`,
                height: '100%',
                right: 0,
                background: alpha(TeamColours[Team.USA], 0.5)
            }}/>
            <Box sx={{
                fontSize: '3rem',
                fontWeight: '900',
                right: '10px',
                letterSpacing: '0',
                lineHeight: '48px',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: '2',
                color: 'white'
            }}>
                <span>{usaConfirmed.hole}</span>
                <span style={{
                    display: usaConfirmed.half ? 'inline-block' : 'none',
                    fontSize: "1rem",
                    fontWeight: "700",
                    letterSpacing: "-.71px",
                    lineHeight: "14px",
                    marginLeft: "5px",
                    position: "relative",
                    top: "-22px",
                }}>1/2</span>
            </Box>
            <Box sx={{
                background: 'linear-gradient(90deg,transparent calc(100% - 1px),hsla(0,0%,100%,.5) 0)',
                backgroundSize: `calc(${100 / pointsOnOffer}% + 0.03571px) 100%`,
                height: '100%',
                position: 'absolute',
                width: '100%',
            }}/>
        </Box>
    );
}

const Home = () => {
    const [user] = useAuthState(auth);
    const {selectedCompetitionId} = useSelectedCompetition();
    const [competition] = useDocumentData(competitionDocumentRef(selectedCompetitionId))
    const [selectedEvent, selectEvent] = useLocalStorage<string | undefined>("SELECTED_EVENT", undefined);
    const previousCompetition = usePrevious(competition);

    useEffect(() => {
        if ((previousCompetition?.id !== competition?.id) && competition !== undefined && selectedEvent === undefined) {
            const firstEvent = competition.events[0];
            if (firstEvent) selectEvent(firstEvent.name)
        }
    }, [competition, selectedCompetitionId]);

    if (!user || !competition) {
        return null;
    }

    return (
        <>
            <NavigationBar>{null}</NavigationBar>
            <PageWrapper>
                <ScoreTracker competition={competition}/>
                <ContentWrapper>
                    <Spacer spacing={2}>
                        <>
                            <FormControl fullWidth>
                                <NativeSelect
                                    value={selectedEvent}
                                    onChange={(event) => selectEvent(event.target.value)}
                                    sx={{
                                        background: 'white',
                                        padding: '0.25rem 1rem',
                                        border: '1px solid #dfdfdf',
                                        borderRadius: '4px',
                                        '&:before': {content: 'none'}
                                    }}
                                >
                                    {competition.events.map(event => (
                                        <option value={event.name}>{event.name}</option>))}
                                </NativeSelect>
                            </FormControl>
                            {
                                competition
                                    .events
                                    .filter(event => event.name === selectedEvent)
                                    .map(event => (
                                        event.matches
                                            .map((match) => {
                                                const teams = groupBy(sort(match.players).byProp('team'), (player) => player.team) as Record<Team, Player[]>;
                                                const europePlayers = teams[Team.EUROPE];
                                                const usaPlayers = teams[Team.USA]

                                                return (
                                                    <MatchCard
                                                        competition={competition}
                                                        event={event}
                                                        match={match}
                                                        europePlayers={europePlayers}
                                                        usaPlayers={usaPlayers}/>
                                                )
                                            })
                                    ))
                            }
                        </>
                    </Spacer>
                </ContentWrapper>
            </PageWrapper>
        </>
    )
};

export default Home;
