import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import AuthContainer from "../components/auth/AuthContainer";
import Center from "../components/utils/Center";
import {auth} from "src/config/firebase";

interface Props {
}

const tabIdToURL: { [id: number]: string } = {
    0: "login",
    1: "register",
};

const Login = ({}: Props) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (auth.currentUser) {
            navigate({
                pathname: '/',
                search: '?',
                hash: ''
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Center height={90}>
            <img alt={"Ryder Cup logo"} src={"/logos/logo-large.png"} style={{
                height: '160px',
                width: 'auto'
            }}/>
            <br/>
            <br/>
            <AuthContainer/>
        </Center>
    );
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export default Login;
