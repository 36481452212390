import Home from "src/screens/Home";
import Login from "src/screens/Login";
import {MatchScreen} from "src/screens/Match";
import Players from "src/screens/Players";
import Player from "src/screens/Player";
import Admin from "src/screens/Admin";
import Awards from "src/screens/Awards";
import Leaderboard from "src/screens/Leaderboard";

interface RouteType {
    path: string;
    component: any;
    name: string;
    protected: boolean;
}

const routes: RouteType[] = [
    {
        path: "",
        component: Home,
        name: "Home",
        protected: true,
    },
    {
        path: "/competition/:competitionId/event/:eventId/match/:matchId",
        component: MatchScreen,
        name: "Match",
        protected: true,
    },
    {
        path: "/competition/:competitionId/players",
        component: Players,
        name: "Players",
        protected: true,
    },
    {
        path: "/awards/:competitionId?",
        component: Awards,
        name: "Awards",
        protected: true,
    },
    {
        path: "/leaderboard/:competitionId?",
        component: Leaderboard,
        name: "Awards",
        protected: true,
    },
    {
        path: "/players/:playerId",
        component: Player,
        name: "Player",
        protected: true,
    },
    {
        path: "/login",
        component: Login,
        name: "Login Screen",
        protected: false,
    },
    {
        path: "/admin",
        component: Admin,
        name: "Admin",
        protected: true,
    },
];

export default routes;
