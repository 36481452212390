import Container from "@mui/material/Container";

type Props = {
    children: React.ReactNode;
    "data-testid"?: string;
};

export const PageWrapper = ({children, ...props}: Props) => {
    return (
        <Container
            sx={{
                mt: 7
            }}
            disableGutters
            maxWidth={false}
            data-testid={props["data-testid"]}
        >
            {children}
        </Container>
    );
};

export const ContentWrapper = ({children, ...props}: Props) => {
    return (
        <Container
            sx={{
                p: 2,
            }}
            disableGutters
            maxWidth={false}
            data-testid={props["data-testid"]}
        >
            {children}
        </Container>
    );
};
