import Stack from "@mui/material/Stack";

type Props = {
  children: React.ReactNode;
  spacing?: number;
};

const Spacer: React.FC<Props> = ({ children, spacing = 2 }) => {
  return <Stack spacing={spacing}>{children}</Stack>;
};

export default Spacer;
