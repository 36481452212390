import {NavigationBar, useSelectedCompetition} from "src/components/Navigation";
import {ContentWrapper, PageWrapper} from "src/components/ContentWrapper/ContentWrapper";
import {auth, competitionDocumentRef, playerCollection} from "src/config/firebase";
import {useCollectionData, useDocumentData} from "react-firebase-hooks/firestore";
import {
    Avatar,
    Box,
    Breadcrumbs,
    Button,
    Card,
    Chip,
    Divider,
    Grid,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    Theme,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import Spacer from "src/components/Spacer/Spacer";
import {useAuthState} from "react-firebase-hooks/auth";
import React from "react";
import {Link, useParams} from "react-router-dom";
import {groupBy, isNotUndefined, uniqBy} from "src/utils/collectionUtils";
import {sortBy} from "ramda";
import {Classification, CompetitionHistory, MatchSummaryOutcome, Player, Team} from "src/types/competitions";
import {TeamColours} from "src/screens/Home";
import {SxProps} from "@mui/system";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const CLASSIFICATION_MAP: Record<Classification, string> = {
    [Classification.PRO]: 'Pro',
    [Classification.AMATEUR]: 'Amateur'
}
const CLASSIFICATION_COLOUR: Record<Classification, string> = {
    [Classification.PRO]: '#8480f6',
    [Classification.AMATEUR]: '#19b54d'
}

export const ClassificationChip = ({value, sx}: { value: Classification, sx?: SxProps<Theme>; }) => {
    return <Chip
        label={CLASSIFICATION_MAP[value]}
        size={"small"}
        sx={{
            textTransform: "uppercase",
            background: CLASSIFICATION_COLOUR[value],
            color: 'white',
            fontSize: "0.7rem",
            height: "18px",
            ...sx
        }}
    />
};

export const PlayerListItem = ({player}: { player: Player }) => {
    console.log(player.competitionHistory)
    const outcomes = player.competitionHistory
        .flatMap(competition => competition.matches)
        .reduce<Record<MatchSummaryOutcome, CompetitionHistory[]>>(
            (obj, matchSummary) => {
                console.log(player.id, matchSummary.matchId, matchSummary.outcome)
                return ({...obj, [matchSummary.outcome]: [...obj[matchSummary.outcome], matchSummary]});
            }
            , {
                [MatchSummaryOutcome.WIN]: [],
                [MatchSummaryOutcome.DRAW]: [],
                [MatchSummaryOutcome.LOSS]: [],
            }
        )

    return (
        <ListItemButton key={player.user_id} alignItems="flex-start" component={Link} to={`/players/${player.id}`}
                        state={{prevPath: window.location.pathname}}>
            <ListItemAvatar>
                <Avatar alt={player.name}
                        src="/static/images/avatar/1.jpg">
                    {player.name.split(' ').map(s => s[0])}
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={
                    [
                        player.name,
                        player.captain ? '(C)' : undefined,
                        player.vice_captain ? '(VC)' : undefined,
                    ].filter(isNotUndefined).join(' ')
                }
                secondary={
                    <Stack direction={"row"} alignItems={"center"}>
                        <ClassificationChip
                            value={player.classification}/>
                        <Box sx={{ml: 1}}>{player.handicap_index}</Box>
                    </Stack>
                }
            />
            <ListItemSecondaryAction>
                <Typography variant={"body1"}>
                    {outcomes.WIN.length}-{outcomes.DRAW.length}-{outcomes.LOSS.length}
                </Typography>
            </ListItemSecondaryAction>
        </ListItemButton>
    );
}

const Players = () => {
    const {competitionId} = useParams();
    if (!competitionId) throw new Error('Expected a competitionId')

    const theme = useTheme();
    const mediumAndUp = useMediaQuery(theme.breakpoints.up('md'));
    const [user] = useAuthState(auth);
    const {selectedCompetitionId} = useSelectedCompetition();
    const [competition] = useDocumentData(competitionDocumentRef(selectedCompetitionId))
    const [players] = useCollectionData(playerCollection)

    if (!user || !players || !competition) return null;

    const playerIdsInComp = competition.events.flatMap(event => event.matches.flatMap(match => match.players)).map(player => player.id);
    const playersInComp = uniqBy(
        players.filter(p => playerIdsInComp.includes(p.id)),
        player => player.user_id
    )

    const playersPerTeam = groupBy(
        playersInComp,
        player => player.team,
    )

    return (
        <>
            <NavigationBar>{null}</NavigationBar>
            <PageWrapper>
                <ContentWrapper>
                    <Breadcrumbs sx={{mt: -3, mb: 1}}>
                        <Button component={Link}
                                to={`/`}
                                style={{color: 'inherit', textDecoration: 'none'}}
                                startIcon={<ArrowBackIcon fontSize={"small"}/>}>
                            {competition.name}
                        </Button>
                    </Breadcrumbs>

                    <Box sx={{mb: 1}}>
                        <Typography variant={"caption"}>Click on a players name to view their match history</Typography>
                    </Box>

                    {
                        !mediumAndUp &&
                        <>
                            <Card sx={{"& *": {listStyle: 'none'}}}>
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar/>
                                    <ListItemText primary={"Player"} sx={{color: 'inherit', fontWeight: 'bold'}}/>
                                    <ListItemSecondaryAction>
                                        <Typography variant={"body1"}>W-D-L</Typography>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </Card>
                            <br/>
                        </>
                    }

                    <Grid container spacing={2}>
                        {
                            Object.entries(playersPerTeam).map(([team, players], teamIndex) => {
                                return (
                                    <Grid item xs={12} md={6}>
                                        <Spacer key={team} spacing={2}>
                                            {mediumAndUp &&
                                                <Card sx={{"& *": {listStyle: 'none'}}}>
                                                    <ListItem alignItems="flex-start">
                                                        <ListItemAvatar/>
                                                        <ListItemText primary={"Player"}
                                                                      sx={{
                                                                          color: 'inherit',
                                                                          fontWeight: 'bold'
                                                                      }}/>
                                                        <ListItemSecondaryAction>
                                                            <Typography variant={"body1"}>W-D-L</Typography>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                </Card>
                                            }
                                            <Card title={team}>
                                                <ListItem alignItems="flex-start"
                                                          sx={{
                                                              background: TeamColours[team as Team],
                                                              color: 'white'
                                                          }}>
                                                    <ListItemText primary={team} sx={{fontWeight: 'bold'}}/>
                                                </ListItem>
                                                <Divider/>
                                                {
                                                    sortBy(player => player.handicap_index, players).map((player, index) => (
                                                        <>
                                                            {index > 0 && <Divider/>}
                                                            <PlayerListItem player={player}/>
                                                        </>
                                                    ))
                                                }
                                            </Card>
                                        </Spacer>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </ContentWrapper>
            </PageWrapper>
        </>
    )
};

export default Players;
