import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {NavigationBar, useSelectedCompetition} from "src/components/Navigation";
import {ContentWrapper, PageWrapper} from "src/components/ContentWrapper/ContentWrapper";
import {competitionCollection, playerDocumentRef} from "src/config/firebase";
import {useCollectionData, useDocumentData} from "react-firebase-hooks/firestore";
import {
    Avatar,
    Box,
    Breadcrumbs,
    Button,
    Card,
    Chip,
    ListItemAvatar,
    ListItemText,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import React from "react";
import {Link, useParams} from "react-router-dom";
import {ClassificationChip} from "src/screens/Players";
import Spacer from "src/components/Spacer/Spacer";
import {CompetitionHistory, CompetitionHistoryMatch, MatchSummaryOutcome} from "src/types/competitions";
import {gte, match} from "src/utils/fpUtils";
import {ChipOwnProps} from "@mui/material/Chip/Chip";
import Typography from "@mui/material/Typography";
import {isNonEmptyArray, toList} from "src/utils/collectionUtils";
import {safeNumber} from "src/utils/numberUtils";


const Player = () => {
    const {playerId} = useParams();
    if (!playerId) throw new Error('Expected a playerId')

    const {selectedCompetitionId} = useSelectedCompetition();
    const [player] = useDocumentData(playerDocumentRef(playerId))
    const [competitions] = useCollectionData(competitionCollection)
    const competition = toList(competitions).find(({id}) => selectedCompetitionId === id);
    if (!player || !competitions || !competition) return null;

    const outcomes = player.competitionHistory.flatMap(comp => comp.matches).reduce<Record<MatchSummaryOutcome, CompetitionHistory[]>>(
        (obj, matchSummary) =>
            ({...obj, [matchSummary.outcome]: [...obj[matchSummary.outcome], matchSummary]})
        , {
            [MatchSummaryOutcome.WIN]: [],
            [MatchSummaryOutcome.DRAW]: [],
            [MatchSummaryOutcome.LOSS]: [],
        }
    )

    return (
        <>
            <NavigationBar>{null}</NavigationBar>
            <PageWrapper>
                <ContentWrapper>
                    <Breadcrumbs sx={{mt: -3}}>
                        <Button component={Link}
                                to={`/`}
                                style={{color: 'inherit', textDecoration: 'none'}}
                                startIcon={<ArrowBackIcon fontSize={"small"}/>}>
                            {competition.name}
                        </Button>
                        <Button component={Link}
                                to={`/competition/${selectedCompetitionId}/players`}
                                style={{color: 'inherit', textDecoration: 'none'}}>
                            Players
                        </Button>
                    </Breadcrumbs>
                    <Spacer>
                        <Stack direction={"row"} alignItems={"center"}>
                            <ListItemAvatar>
                                <Avatar alt={player.name}>
                                    {player.name.split(' ').map(s => s[0])}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={<Box sx={{display: 'flex', alignItems: 'center'}}>
                                <span>{player.name}</span>
                                <ClassificationChip value={player.classification} sx={{ml: 2}}/>
                            </Box>
                            } primaryTypographyProps={{variant: 'h6'}}
                                          secondary={`Won ${outcomes.WIN.length} - Drawn ${outcomes.DRAW.length} - Lost ${outcomes.LOSS.length}`}/>
                        </Stack>

                        <Card>
                            <Table size={"small"}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Match</TableCell>
                                        <TableCell>Outcome</TableCell>
                                        <TableCell>Team</TableCell>
                                        <TableCell align={"right"}>Gross</TableCell>
                                        <TableCell align={"right"}>Nett</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Lifetime</TableCell>
                                        <TableCell/>
                                        <TableCell/>
                                        <TableCell align={"right"}
                                                   sx={{whiteSpace: 'nowrap'}}>{calculateGrossScores(player.competitionHistory.flatMap(comp => comp.matches))}</TableCell>
                                        <TableCell align={"right"}
                                                   sx={{whiteSpace: 'nowrap'}}>{calculateNettScores(player.competitionHistory.flatMap(comp => comp.matches))}</TableCell>
                                    </TableRow>
                                    {
                                        player.competitionHistory.sort((a, b) => b.year - a.year).map((comp, index) => (
                                            <React.Fragment key={comp.competitionId}>
                                                <TableRow
                                                    sx={{'& td': {background: theme => theme.palette.background.default}}}>
                                                    <TableCell colSpan={5}/>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell colSpan={5}>
                                                        <Stack direction={"row"} spacing={1} alignItems={"flex-end"}
                                                               justifyContent={"space-between"}>
                                                            <Typography variant={"body2"} fontWeight={500}>
                                                                {comp.name}
                                                            </Typography>
                                                            <Typography variant={"body2"} fontWeight={500}>
                                                                HC: {getHandicap(comp.matches)}
                                                            </Typography>
                                                        </Stack>
                                                        {
                                                            isNonEmptyArray(comp.awards) &&
                                                            <Stack direction={"row"} spacing={1} sx={{mt: 1}}>
                                                                {comp.awards.map(accolade => <Chip
                                                                    label={accolade} size={"small"}/>)}
                                                            </Stack>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                                {
                                                    comp.matches.map(matchSummary => (
                                                        <TableRow key={matchSummary.matchId}>
                                                            <TableCell>{matchSummary.matchName}</TableCell>
                                                            <TableCell>
                                                                <OutcomeChip outcome={matchSummary.outcome}/>
                                                            </TableCell>
                                                            <TableCell>{matchSummary.team}</TableCell>
                                                            <TableCell
                                                                align={"right"}>{calculateGrossScores([matchSummary])}</TableCell>
                                                            <TableCell
                                                                align={"right"}>{calculateNettScores([matchSummary])}</TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                                <TableRow sx={{'&:last-child td': {borderBottom: 'none'}}}>
                                                    <TableCell>
                                                        <Typography variant={"body2"}
                                                                    fontWeight={500}>Total</Typography>
                                                    </TableCell>
                                                    <TableCell/>
                                                    <TableCell/>
                                                    <TableCell align={"right"} sx={{whiteSpace: 'nowrap'}}>
                                                        <Typography variant={"body2"} fontWeight={500}>
                                                            {calculateGrossScores(comp.matches)}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align={"right"} sx={{whiteSpace: 'nowrap'}}>
                                                        <Typography variant={"body2"} fontWeight={500}>
                                                            {calculateNettScores(comp.matches)}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </Card>
                    </Spacer>
                </ContentWrapper>
            </PageWrapper>
        </>
    )
};

export const scoreToPar = (num: number): string => match(num).with(gte(0), (val) => `+${val}`).otherwise((val) => val.toString())


export const getHandicap = (matches: CompetitionHistoryMatch[]) => matches.find(m => m.handicap_index !== undefined)?.handicap_index;
export const calculateNettScores = (matches: CompetitionHistoryMatch[]): string => calculateNett(matches) ? `${calculateNettToPar(matches)} (${calculateNett(matches)})` : '';
export const calculateNett = (matches: CompetitionHistoryMatch[]): number | undefined => {
    const matchesWithNettScore = matches.filter(m => m.nett_score !== undefined);
    return matchesWithNettScore.length > 0 ? matchesWithNettScore.reduce((sum, {nett_score}) => safeNumber(sum + (nett_score ?? 0)), 0) : undefined;
}
export const calculateNettToPar = (matches: CompetitionHistoryMatch[]): string => scoreToPar(
    matches.reduce((sum, {
        nett_score_to_par,
    }) => safeNumber(sum + (nett_score_to_par ? nett_score_to_par : 0)), 0)
)
export const calculateGrossScores = (matches: CompetitionHistoryMatch[]): string => calculateGross(matches) ? `${calculateGrossToPar(matches)} (${calculateGross(matches)})` : '';
export const calculateGross = (matches: CompetitionHistoryMatch[]): number | undefined => {
    const matchesWithNettScore = matches.filter(m => m.gross_score !== undefined);
    return matchesWithNettScore.length > 0 ? matchesWithNettScore.reduce((sum, {gross_score}) => safeNumber(sum + (gross_score ?? 0)), 0) : undefined;
}
export const calculateGrossToPar = (matches: CompetitionHistoryMatch[]): string => scoreToPar(
    matches.reduce((sum, {
        gross_score_to_par,
    }) => safeNumber(sum + (gross_score_to_par ? gross_score_to_par : 0)), 0)
)

export const OutcomeChip = ({outcome}: { outcome: MatchSummaryOutcome }) => {
    const color = match<MatchSummaryOutcome, ChipOwnProps['color']>(outcome)
        .with(MatchSummaryOutcome.WIN, () => 'success')
        .with(MatchSummaryOutcome.LOSS, () => 'error')
        .with(MatchSummaryOutcome.DRAW, () => 'info')
        .exhaustive()

    return <Chip label={outcome} size={"small"} color={color}/>;
}

export default Player;
