export const currencyFormatter = (centAmount: number) =>
  new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(centAmount / 100);

export const percentageFormatter = (number: number) => {
  return new Intl.NumberFormat("en-GB", {
    style: "percent",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  }).format(number / 100);
};

// TODO: If we're going to be doing more math, we should really use a library like BigDecimal or DecimalJS
export const safeNumber = (num: number) => parseFloat(num.toPrecision(10));
