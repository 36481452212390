import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {StylesProvider} from "src/contexts/styles";
import {SelectedCompetitionProvider} from "src/components/Navigation";

const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);

root.render(
    <React.StrictMode>
        <StylesProvider>
            <SelectedCompetitionProvider>
                <App/>
            </SelectedCompetitionProvider>
        </StylesProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
