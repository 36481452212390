import {PaletteMode} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import {ThemeProvider} from "@mui/material/styles";
import React from "react";
import {getTheme} from "src/contexts/styles/materialTheme";
import {useLocalStorage} from "src/hooks/useLocalStorage";

type Props = {
    children: React.ReactNode;
};

type ColourModeContextProps = {
    toggleColourMode: () => void;
    mode: PaletteMode;
};

const DISPLAY_MODE_KEY = "display-mode";

export const ColourModeContext = React.createContext<ColourModeContextProps>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    toggleColourMode: () => {
    },
    mode: "light"
});

export const StylesProvider = ({children}: Props) => {
    const [mode, setMode] = useLocalStorage<PaletteMode>(DISPLAY_MODE_KEY, "light");
    const colourMode = React.useMemo(
        () => ({
            toggleColourMode: () => {
                setMode(prevMode => (prevMode === "light" ? "dark" : "light"));
            },
            mode
        }),
        [mode]
    );

    const theme = React.useMemo(() => getTheme(mode), [mode]);

    return (
        <ColourModeContext.Provider value={colourMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                {children}
            </ThemeProvider>
        </ColourModeContext.Provider>
    );
};
