import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {NavigationBar} from "src/components/Navigation";
import {ContentWrapper, PageWrapper} from "src/components/ContentWrapper/ContentWrapper";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDocumentData} from "react-firebase-hooks/firestore";
import {competitionDocumentRef} from "src/config/firebase";
import Spacer from "src/components/Spacer/Spacer";
import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Grid,
    IconButton,
    lighten,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery, useTheme
} from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import {
    Competition,
    CourseHole,
    HoleResult,
    CompetitionMatch,
    Outcome,
    OutrightWinner,
    Player,
    Team
} from "src/types/competitions";
import {groupBy} from "src/utils/collectionUtils";
import {OutcomeColours, TeamColours} from "src/screens/Home";
import {updateDoc} from "@firebase/firestore";
import {match, match as fpMatch} from "src/utils/fpUtils";
import {usePrevious} from "src/utils/hookUtils";
import {P} from "ts-pattern";
import {useLocalStorage} from "src/hooks/useLocalStorage";

const cellStyles = ({selectedHole}: { selectedHole?: boolean } = {}) => ({
    position: 'relative',
    p: 0,
    minWidth: '34px',
    fontSize: '.7rem',
    textAlign: 'center',
    borderLeft: '1px solid #cecece',
    borderRight: '1px solid #cecece',
    background: selectedHole ? '#ece9e9' : 'white'
});
const firstCellStyles = {
    ...cellStyles(),
    borderTop: '1px solid #cecece',
}

export const MatchScreen = () => {
    const {competitionId, eventId, matchId} = useParams();
    if (!competitionId) throw new Error('Expected a competitionId')

    const [competition] = useDocumentData(competitionDocumentRef(competitionId))
    const event = (competition?.events ?? []).find(
        event => event.id === eventId
    )
    const match = event?.matches.find(
        match => match.id === matchId
    )
    const previousMatch = usePrevious(match);
    const holes = match?.players[0].holes.map<CourseHole>(hole => hole) ?? [];
    const lowestHoleNumbers = (match?.players ?? []).map(player => player.holes.find(hole => hole.gross_score === undefined)?.number ?? 1);
    const [selectedHole, setSelectedHole] = useState<CourseHole | undefined>(undefined);

    useEffect(() => {
        if (previousMatch === undefined) {
            setSelectedHole(holes[Math.min.apply(Math, lowestHoleNumbers) - 1] ?? holes[0])
        }
    }, [match]);

    if (!competition || !match || !selectedHole) return null;

    return (
        <>
            <NavigationBar>{null}</NavigationBar>
            <PageWrapper>
                <Spacer>
                    <Box sx={{marginTop: "-24px !important", mb: '12px !important', textAlign: 'center'}}>
                        <Typography variant={"subtitle2"} align={"center"}>
                            {competition.name}
                        </Typography>
                        <Typography align={"center"}>
                            {event?.name} - {match?.name}
                        </Typography>

                        <Chip size={"small"} label={`${match.tee} tees`} sx={{
                            background: match.tee.toLowerCase(),
                            color: ['yellow', 'white'].includes(match.tee.toLowerCase()) ? 'black' : 'white',
                        }}/>

                        <Box sx={{
                            pt: 1,
                            fontSize: '.6rem',
                            textAlign: 'center',
                        }}>
                            <Box sx={{
                                display: 'inline-block',
                            }}>
                                CP = Closest to pin
                            </Box>
                            <Box sx={{
                                display: 'inline-block',
                                ml: 4
                            }}>
                                LD = Longest drive
                            </Box>
                        </Box>
                    </Box>
                    <Scorecard
                        match={match}
                        selectedHole={selectedHole}
                        selectHole={(holeNumber) => setSelectedHole(holes[holeNumber - 1])}
                    />
                    <ContentWrapper>
                        <ScoreEntry
                            match={match}
                            selectedHole={selectedHole}
                            setSelectedHole={(holeNumber) => setSelectedHole(holes[holeNumber])}
                            onChange={async ({playerId, holeNumber, grossScore}: {
                                playerId: string,
                                holeNumber: number,
                                grossScore: number
                            }) => {
                                const updatedPlayers = match.players.map(player => {
                                    if (player.user_id !== playerId) return player;
                                    return {
                                        ...player,
                                        holes: player.holes.map(hole => {
                                            if (hole.number !== holeNumber) return hole;
                                            return ({
                                                ...hole,
                                                gross_score: grossScore,
                                                nett_score: grossScore - hole.shots_given
                                            });
                                        })
                                    };
                                });
                                const updatedResults = match.results.map<HoleResult>((_outcome, index) => {
                                    const nettScoreOnHole = updatedPlayers.map(p => ({
                                        id: p.user_id,
                                        team: p.team,
                                        nett_score: p.holes[index].nett_score
                                    }))

                                    if (nettScoreOnHole.some(hole => hole.nett_score === undefined)) {
                                        return {winner: Outcome.UNKNOWN}
                                    }

                                    const bestScoreByTeam = groupBy(nettScoreOnHole, o => o.team);
                                    const usaScore = bestScoreByTeam[Team.USA].reduce<number | undefined>((bestScore, o) => {
                                        if (bestScore === undefined) return o.nett_score;
                                        if (o.nett_score === undefined) return bestScore;
                                        return o.nett_score < bestScore ? o.nett_score : bestScore;
                                    }, undefined)
                                    const europeScore = bestScoreByTeam[Team.EUROPE].reduce<number | undefined>((bestScore, o) => {
                                        if (bestScore === undefined) return o.nett_score;
                                        if (o.nett_score === undefined) return bestScore;
                                        return o.nett_score < bestScore ? o.nett_score : bestScore;
                                    }, undefined)

                                    if (usaScore === undefined || europeScore === undefined) return {winner: Outcome.UNKNOWN}

                                    const result = fpMatch<{
                                        usaWin: boolean,
                                        europeWin: boolean,
                                        tie: boolean
                                    }, HoleResult>({
                                        usaWin: usaScore < europeScore,
                                        europeWin: europeScore < usaScore,
                                        tie: europeScore === usaScore
                                    })
                                        .with({usaWin: true}, () => ({
                                            winner: Outcome.TEAM_USA,
                                            nett_score: usaScore
                                        }))
                                        .with({europeWin: true}, () => ({
                                            winner: Outcome.TEAM_EUROPE,
                                            nett_score: europeScore
                                        }))
                                        .with({tie: true}, () => ({
                                            winner: Outcome.TIE,
                                            nett_score: europeScore
                                        }))
                                        .otherwise(() => ({winner: Outcome.UNKNOWN}))

                                    return result;
                                })

                                const updatedMatch: CompetitionMatch = {
                                    ...match,
                                    players: updatedPlayers,
                                    results: updatedResults
                                };

                                const updatedCompetition: Partial<Competition> = {
                                    events: competition.events.map(event => {
                                        if (event.id !== eventId) return event;
                                        return {
                                            ...event,
                                            matches: event.matches.map(match => {
                                                if (match.id !== matchId) return match;
                                                return updatedMatch;
                                            })
                                        }
                                    })
                                }

                                await updateDoc(competitionDocumentRef(competitionId), updatedCompetition)
                            }}/>
                    </ContentWrapper>
                </Spacer>
            </PageWrapper>
        </>
    )
};

const ScoreEntry = ({
                        match,
                        onChange,
                        selectedHole,
                        setSelectedHole
                    }: {
    match: CompetitionMatch,
    selectedHole: CourseHole,
    setSelectedHole: (holeNumber: number) => void,
    onChange: (params: {
        playerId: string,
        holeNumber: number,
        grossScore: number
    }) => Promise<void>
}) => {
    const teams = groupBy(match.players, player => player.team);
    const europePlayers = teams[Team.EUROPE];
    const usaPlayers = teams[Team.USA]

    return (
        <Spacer>
            <Stack direction={'row'} justifyContent={"center"} alignItems={"center"} spacing={2}>
                <IconButton onClick={() => setSelectedHole(selectedHole.number - 2)}
                            disabled={selectedHole.number === 1}>
                    <ArrowBack/>
                </IconButton>
                <Typography align={"center"}>Hole {selectedHole.number}</Typography>
                <IconButton onClick={() => setSelectedHole(selectedHole.number)}
                            disabled={selectedHole.number === 18}>
                    <ArrowForward/>
                </IconButton>
            </Stack>
            <Stack direction={'row'} justifyContent={"center"} alignItems={"center"} spacing={6}>
                <Spacer>
                    {europePlayers.map(player => {
                        return (
                            <Box sx={{flex: 1, border: `1px solid ${TeamColours[Team.EUROPE]}`, borderRadius: '4px'}}
                                 key={player.user_id}>
                                <ScoreInput
                                    player={player}
                                    hole={selectedHole}
                                    team={Team.EUROPE}
                                    onChange={grossScore => onChange({
                                        playerId: player.user_id,
                                        holeNumber: selectedHole.number,
                                        grossScore
                                    })}
                                />
                            </Box>
                        );
                    })}
                </Spacer>
                <Spacer>
                    {usaPlayers.map(player => {
                        return (
                            <Box sx={{flex: 1, border: `1px solid ${TeamColours[Team.USA]}`, borderRadius: '4px'}}
                                 key={player.user_id}>
                                <ScoreInput
                                    player={player}
                                    hole={selectedHole}
                                    team={Team.USA}
                                    onChange={grossScore => onChange({
                                        playerId: player.user_id,
                                        holeNumber: selectedHole.number,
                                        grossScore
                                    })}
                                />
                            </Box>
                        );
                    })}
                </Spacer>
            </Stack>
        </Spacer>
    )
}

const ScoreInput = ({player, hole, team, onChange}: {
    player: Player,
    hole: CourseHole,
    team: Team,
    onChange: (value: number) => void
}) => {
    const [temp, setTemp] = useState<number>(player.holes[hole.number - 1].gross_score ?? hole.par);
    const parts = player.name.split(' ');

    useEffect(() => {
        setTemp(player.holes[hole.number - 1].gross_score ?? hole.par)
    }, [hole]);

    return (
        <Stack direction={"column"} alignItems={"center"} sx={{borderRadius: '4px'}}>
            <Box sx={{
                width: '100%',
                color: 'white',
                background: TeamColours[team],
                display: 'flex',
                justifyContent: 'center'
            }}>
                <Stack direction={"column"} alignItems={"center"}>
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                        <PlayerFirstName firstName={parts[0]}/>
                        <PlayerLastName lastName={parts[1]}/>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"} spacing={2}>
                        <Button
                            sx={{
                                width: '40px',
                                minWidth: '40px',
                                height: '60px',
                                fontSize: '2rem',
                                color: 'white'
                            }} onClick={() => {
                            setTemp(val => Math.max(1, val - 1))
                        }}>-</Button>
                        <Stack direction={'column'}
                               sx={{
                                   minWidth: '50px',
                                   fontSize: '2rem',
                                   color: 'white'
                               }}
                               alignItems={'center'}
                               justifyContent={'center'}>{temp}</Stack>
                        <Button
                            sx={{
                                width: '40px',
                                minWidth: '40px',
                                height: '60px',
                                fontSize: '2rem',
                                color: 'white'
                            }}
                            onClick={() => {
                                setTemp(val => Math.min(11, val + 1))
                            }}>+</Button>
                    </Stack>
                    <Button
                        fullWidth={true}
                        size={"small"}
                        variant={"text"}
                        sx={{
                            borderRadius: 0,
                            color: 'white',
                            background: TeamColours[team],
                            '&:hover': {
                                background: lighten(TeamColours[team], 0.2)
                            }
                        }}
                        onClick={() => {
                            if (temp) onChange(temp)
                        }}>SAVE</Button>
                </Stack>
            </Box>
        </Stack>
    )
}

enum ScorecardMode {
    COMP = 'COMP',
    SCORE = 'SCORE'
}

const Scorecard = (props: {
    match: CompetitionMatch,
    selectHole: (holeNumber: number) => void,
    selectedHole: CourseHole
}) => {
    const theme = useTheme();
    const [width, setWidth] = useState(126)
    const [scorecardMode, setScorecardMode] = useLocalStorage<ScorecardMode>("advanced_view", ScorecardMode.COMP);
    const [showModal, setShowModal] = useState(false)
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const handleOpen = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    useEffect(() => {
        const maxWidth = Math.max.apply(null, Array.from(document.querySelectorAll('.flex-table tr:nth-child(n+5) td:first-child')).map(el => el.clientWidth)) - 5
        setWidth(maxWidth)
    }, [window.innerWidth]);

    return (
        <Box sx={{position: 'relative'}}>
            <Box
                sx={{
                    position: "absolute",
                    top: "0",
                    left: "0px",
                    marginTop: "0",
                    overflow: "hidden",
                    width: `${width}px`,
                    zIndex: "1000",
                    background: "#f4f4f4",
                }}
            >
                <ScorecardElement {...props} scorecardMode={scorecardMode} scrollable={false}/>
            </Box>
            <Box className={"flex-table"}>
                <ScorecardElement {...props} scorecardMode={scorecardMode}/>
            </Box>
            <Box sx={{pr: 1, pt: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                <Typography variant={"caption"} component={"label"} sx={{lineHeight: 1}}
                            onClick={() => setScorecardMode(ScorecardMode.COMP)}>Comp mode</Typography>
                <Switch checked={scorecardMode === ScorecardMode.SCORE}
                        onChange={() => scorecardMode === ScorecardMode.COMP ? setScorecardMode(ScorecardMode.SCORE) : setScorecardMode(ScorecardMode.COMP)}
                        inputProps={{'aria-label': 'controlled', id: 'scoremodeSwitch'}}
                        size="small"
                />
                <Typography variant={"caption"} component={"label"} sx={{lineHeight: 1}}
                            onClick={() => setScorecardMode(ScorecardMode.SCORE)}>Score mode</Typography>
                <IconButton onClick={handleOpen}>
                    <HelpOutlineIcon sx={{fontSize: "1rem"}}/>
                </IconButton>
            </Box>
            <Dialog
                fullScreen={fullScreen}
                open={showModal}
                onClose={handleClose}
            >
                <DialogContent>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                            <Spacer>
                                <Typography variant={"h6"}>Comp Mode</Typography>
                                <Typography>Competition mode tracks players gross scores and presents them alongside the
                                    shots other players give them.</Typography>
                                <Typography>Shots are given based on the lowest handicap player playing off scratch and
                                    they are capped at 18 shots.</Typography>
                                <img src={"/images/comp-mode-help.png"} style={{ maxWidth: "450px", width: "100%" }} />
                            </Spacer>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Spacer>
                                <Typography variant={"h6"}>Score Mode</Typography>
                                <Typography>Score mode tracks players gross scores and presents them alongside the
                                    shots the course gives to them.</Typography>
                                <Typography>Shots are given based on the players handicap on the course. Consider this to be just like a normal(ish) scorecard.</Typography>
                                <img src={"/images/score-mode-help.png"} style={{ maxWidth: "450px", width: "100%" }} />
                            </Spacer>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

const ScorecardElement = ({match, selectHole, selectedHole, scrollable = true, scorecardMode}: {
    match: CompetitionMatch,
    selectHole: (holeNumber: number) => void,
    selectedHole: CourseHole,
    scrollable?: boolean
    scorecardMode: ScorecardMode
}) => {
    const holes = match.players[0].holes.map(hole => hole);
    const teams = groupBy(match.players, player => player.team);
    const europePlayers = teams[Team.EUROPE];
    const usaPlayers = teams[Team.USA]

    return (
        <Box sx={{overflow: scrollable ? 'auto' : 'hidden', width: '100vw'}}>
            <Table size={"small"}>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2} align={"right"}
                                   sx={{
                                       lineHeight: 1,
                                       fontSize: cellStyles()['fontSize'],
                                       border: 'none',
                                       fontWeight: 'bold'
                                   }}></TableCell>
                        {
                            holes.map(hole => (
                                <TableCell key={`hole-${hole.number}`}
                                           sx={{
                                               position: 'relative',
                                               p: 0,
                                               minWidth: '28px',
                                               fontSize: '.7rem',
                                               textAlign: 'center',
                                           }}>
                                    {hole.longest_drive ? 'LD' : ''}
                                    {hole.closest_pin ? 'CP' : ''}
                                </TableCell>)
                            )
                        }
                        <TableCell colSpan={1} align={"right"}/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={2} align={"right"}
                                   sx={{
                                       lineHeight: 1,
                                       fontSize: cellStyles()['fontSize'],
                                       border: 'none',
                                       fontWeight: 'bold'
                                   }}>Hole</TableCell>
                        {
                            holes.map(hole => (
                                <TableCell key={`hole-${hole.number}`}
                                           sx={{
                                               ...cellStyles({selectedHole: hole.number === selectedHole.number}),
                                               borderTop: cellStyles()['borderLeft'],
                                               fontWeight: 'bold'
                                           }}
                                           onClick={() => selectHole(hole.number)}>
                                    {hole.number}
                                </TableCell>)
                            )
                        }
                        <TableCell sx={{...cellStyles(), borderTop: '1px solid #cecece'}} colSpan={2}/>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} align={"right"}
                                   sx={{
                                       lineHeight: 1,
                                       fontSize: cellStyles()['fontSize'], border: 'none'
                                   }}>Par</TableCell>
                        {
                            holes.map(hole => (
                                <TableCell key={`hole-${hole.number}`}
                                           sx={{...cellStyles({selectedHole: hole.number === selectedHole.number})}}
                                           onClick={() => selectHole(hole.number)}>{hole.par}</TableCell>))
                        }
                        <TableCell sx={{...cellStyles()}}
                                   colSpan={2}>{holes.reduce((sum, hole) => sum + hole.par, 0)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} align={"right"}
                                   sx={{
                                       lineHeight: 1,
                                       fontSize: cellStyles()['fontSize'], border: 'none'
                                   }}>Index</TableCell>
                        {
                            holes.map(hole => (
                                <TableCell key={`hole-${hole.number}`}
                                           sx={{...cellStyles({selectedHole: hole.number === selectedHole.number})}}
                                           onClick={() => selectHole(hole.number)}>{hole.stroke_index}</TableCell>))
                        }
                        <TableCell sx={{...cellStyles()}} colSpan={2}/>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} align={"right"}
                                   sx={{
                                       fontSize: cellStyles()['fontSize'],
                                       border: 'none'
                                   }}>Yards</TableCell>
                        {
                            holes.map(hole => (
                                <TableCell
                                    key={`hole-${hole.number}`}
                                    sx={{...cellStyles({selectedHole: hole.number === selectedHole.number})}}
                                    onClick={() => selectHole(hole.number)}>
                                    {hole.length}
                                </TableCell>
                            ))
                        }
                        <TableCell
                            // @ts-ignore
                            sx={{...cellStyles()}}
                            colSpan={2}>
                            {holes.reduce((sum, hole) => sum + hole.length, 0)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2}
                                   sx={{
                                       background: theme => theme.palette.background.default,
                                       border: 'none'
                                   }}/>
                        <TableCell colSpan={holes.length + 2}
                                   sx={{
                                       background: theme => theme.palette.background.default,
                                       border: 'none'
                                   }}/>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2}
                                   sx={{
                                       background: theme => theme.palette.background.default,
                                       border: 'none'
                                   }}/>
                        <TableCell colSpan={holes.length}/>
                        {
                            ['Nett', 'Gross'].map(str => (
                                    <TableCell key={str}
                                               align={"right"}
                                               sx={{
                                                   ...cellStyles(),
                                                   borderTop: cellStyles()['borderLeft'],
                                                   fontWeight: 'bold',
                                                   padding: '4px 0',
                                                   lineHeight: 1
                                               }}>
                                        {str}
                                    </TableCell>
                                )
                            )
                        }
                    </TableRow>
                    {europePlayers.map((player, index) => <PlayerRow
                        key={player.user_id}
                        firstNameInList={index === 0}
                        player={player}
                        numberOfPlayers={europePlayers.length}
                        holeResults={match.results}
                        selectHole={selectHole}
                        selectedHole={selectedHole}
                        scorecardMode={scorecardMode}
                    />)}
                    <TableRow>
                        <TableCell colSpan={2}
                                   sx={{
                                       background: theme => theme.palette.background.default,
                                       border: 'none'
                                   }}/>
                        <TableCell colSpan={holes.length + 1}/>
                    </TableRow>
                    {usaPlayers.map((player, index) => <PlayerRow
                        key={player.user_id}
                        firstNameInList={index === 0}
                        player={player}
                        numberOfPlayers={usaPlayers.length}
                        holeResults={match.results}
                        selectHole={selectHole}
                        selectedHole={selectedHole}
                        scorecardMode={scorecardMode}
                    />)}
                </TableBody>
            </Table>
        </Box>
    )
}

const PlayerRow = ({player, holeResults, selectHole, selectedHole, firstNameInList, numberOfPlayers, scorecardMode}: {
    player: Player,
    holeResults: HoleResult[];
    selectHole: (holeNumber: number) => void,
    selectedHole: CourseHole,
    firstNameInList: boolean,
    numberOfPlayers: number,
    scorecardMode: ScorecardMode
}) => {
    const parts = player.name.split(' ');
    const teamPoints = holeResults.reduce((count, result) => {
        return match(result.winner as string)
            .with((player.team), () => count + 1)
            .with(Outcome.TIE, () => count)
            .with(Outcome.UNKNOWN, () => count)
            .otherwise(() => count - 1)
    }, 0)
    const teamScore = match(teamPoints)
        .with(P.number.gt(0), () => `${teamPoints}UP`)
        .with(0, () => `A/S`)
        .otherwise(() => undefined)

    return (
        <TableRow key={player.user_id}>
            <TableCell colSpan={2} sx={{
                ...firstCellStyles,
                background: TeamColours[player.team],
                borderLeftColor: TeamColours[player.team],
                borderBottomColor: firstNameInList ? TeamColours[player.team] : 'initial',
                color: 'white',
                padding: '.5rem 30px .5rem .5rem',
                textAlign: 'left'
            }}>
                <Box sx={{pr: 1}}>
                    <PlayerFirstName firstName={parts[0]}/>
                    <PlayerLastName lastName={parts[1]}/>
                </Box>
                {
                    firstNameInList && teamScore && <Box sx={{
                        position: "absolute",
                        top: `calc(${numberOfPlayers * 50}% - 7px)`,
                        right: "6px",
                        zIndex: "100",
                        fontSize: "14px",
                        lineHeight: "14px",
                        height: "14px",
                        fontWeight: "bold",
                    }}>{teamScore}</Box>
                }
            </TableCell>
            {
                player.holes.map((hole, index) => {
                    const holeResult = holeResults[index]
                    const playerResult = match(holeResult.winner.toString())
                        .with(player.team, () => (holeResult as OutrightWinner).nett_score === hole.nett_score ? 'winner' : 'lost')
                        .with(Outcome.TIE, () => (holeResult as OutrightWinner).nett_score === hole.nett_score ? 'tie' : 'lost')
                        .otherwise(() => 'lost')
                    const styles = match(playerResult)
                        .with('winner', () => ({
                            background: TeamColours[player.team],
                            color: 'white'
                        }))
                        .with('tie', () => ({
                            background: OutcomeColours[Outcome.TIE],
                        }))
                        .otherwise(() => ({}))

                    return (
                        <TableCell key={`hole-${hole.number}`}
                                   sx={{
                                       ...cellStyles({selectedHole: hole.number === selectedHole.number}),
                                       ...styles,
                                   }}
                                   onClick={() => selectHole(hole.number)}>
                            {
                                scorecardMode === ScorecardMode.COMP && (
                                    <>
                                        <Box
                                            sx={{
                                                ...styles,
                                                fontWeight: 'bold',
                                            }}>
                                            {hole.gross_score}
                                        </Box>
                                        <Box
                                            sx={{
                                                ...styles,
                                                position: 'absolute',
                                                top: 2,
                                                left: 3,
                                            }}>
                                            {hole.shots_given > 0 ? hole.shots_given : null}
                                        </Box>
                                    </>
                                )
                            }
                            {
                                scorecardMode === ScorecardMode.SCORE && (
                                    <>
                                        <Box
                                            sx={{
                                                ...styles,
                                                fontWeight: 'bold',
                                            }}>
                                            {hole.gross_score !== undefined && `${hole.gross_score}`}
                                        </Box>
                                        <Box
                                            sx={{
                                                ...styles,
                                            }}>
                                            {hole.gross_score !== undefined && `(${hole.gross_score - hole.course_shots_given})`}
                                        </Box>
                                    </>
                                )
                            }
                        </TableCell>
                    );
                })
            }
            <TableCell sx={{...cellStyles()}}>
                <Box sx={{fontWeight: 'bold'}}>
                    {player.holes.reduce((sum, hole) => sum + (hole.gross_score ? (hole.gross_score - hole.course_shots_given) : 0), 0)}
                </Box>
                <Box>
                    {(player.holes.reduce((sum, hole) => sum + (hole.gross_score ? (hole.gross_score - hole.course_shots_given) : 0), 0)) - player.holes.reduce((sum, hole) => hole.gross_score === undefined ? sum : sum + hole.par, 0)}
                </Box>
            </TableCell>
            <TableCell sx={{...cellStyles()}}>
                <Box sx={{fontWeight: 'bold'}}>
                    {player.holes.reduce((sum, hole) => sum + (hole.gross_score ? hole.gross_score : 0), 0)}
                </Box>
                <Box>
                    {(player.holes.reduce((sum, hole) => sum + (hole.gross_score ? (hole.gross_score) : 0), 0)) - player.holes.reduce((sum, hole) => hole.gross_score === undefined ? sum : sum + hole.par, 0)}
                </Box>
            </TableCell>
        </TableRow>
    );
};

export const PlayerFirstName = ({firstName}: { firstName: string }) => (
    <Typography
        variant="subtitle1"
        sx={{
            fontStyle: 'italic',
            m: 0,
            lineHeight: '1',
            fontSize: '0.65rem'
        }}>{firstName}</Typography>
);

export const PlayerLastName = ({lastName}: { lastName: string }) => (
    <Typography
        sx={{
            fontWeight: 'bold',
            textTransform: 'uppercase',
            lineHeight: '2',
            fontSize: '0.85em'
        }}
    >{lastName}</Typography>
);