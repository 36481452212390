import { match, P } from "ts-pattern";

const anyValue = P.any;
const isDefined = P.not(undefined);
const isString = P.string;
const unionOf = P.union;
const isNot = P.not;
const numberOp = P.number;
const isBoolean = P.boolean;
const noOp = () => undefined;

const gt = P.number.gt
const gte = P.number.gte

export { anyValue, isBoolean, isDefined, isNot, isString, match, noOp, numberOp, unionOf, gt, gte};
