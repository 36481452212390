export enum Team {
    USA = 'USA',
    EUROPE = 'Europe'
}

export enum Classification {
    PRO = 'Pro',
    AMATEUR = 'Amateur'
}

export type TeeColour = 'White' | 'Blue' | 'Yellow' | 'Red';
export type HoleLength = Record<TeeColour, number | undefined>

export type Tee = {
    name: string;
    course_rating: number;
    slope_rating: number;
}

export type Course = {
    name: string;
    defaultTee: TeeColour;
    holes: CourseHoleConfig[];
    tees: Tee[];
}

export type CourseHoleConfig = {
    number: number,
    name: string,
    par: number,
    stroke_index: number,
    length: HoleLength[]
}

export type CourseHole = {
    number: number,
    name: string,
    par: number,
    stroke_index: number,
    length: number
    longest_drive: boolean;
    closest_pin: boolean;
}

export type CourseHoleWithScore = CourseHole & {
    shots_given: number;
    course_shots_given: number;
    gross_score?: number;
    nett_score?: number;
}

export enum Outcome {
    TEAM_USA = "USA",
    TEAM_EUROPE = "Europe",
    TIE = 'tie',
    UNKNOWN = 'unknown'
}

export type OutrightWinner = {
    winner: Outcome.TEAM_USA | Outcome.TEAM_EUROPE
    nett_score: number;
}
export type TiedWinner = {
    winner: Outcome.TIE
    nett_score: number;
}
export type UnknownWinner = {
    winner: Outcome.UNKNOWN
}

export type HoleResult = OutrightWinner | TiedWinner | UnknownWinner

export type CourseHoleWithOutcome = CourseHoleWithScore & { outcome: Outcome }

export enum MatchSummaryOutcome {
    WIN = "WIN",
    DRAW = "DRAW",
    LOSS = "LOSS",
}

export type CompetitionHistoryMatch = {
    eventId: string
    matchId: string
    matchName: string
    handicap_index: number
    classification: Classification;
    par: number
    gross_score?: number
    gross_score_to_par?: number
    nett_score?: number
    nett_score_to_par?: number
    team: Team
    outcome: MatchSummaryOutcome
}

export type CompetitionHistory = {
    competitionId: string
    year: number;
    name: string;
    awards: string[]
    matches: CompetitionHistoryMatch[]
}

export type Player = {
    id: string;
    course_handicap: number;
    handicap_index: number;
    user_id: string;
    name: string;
    team: Team;
    classification: Classification;
    holes: CourseHoleWithScore[]
    competitionHistory: CompetitionHistory[]
    captain: boolean;
    vice_captain: boolean;
}

export type AdditionalPoints = {
    [Team.USA]: number,
    [Team.EUROPE]: number
};

export type CompetitionMatch = {
    id: string;
    name: string;
    tee: TeeColour;
    players: Player[];
    results: HoleResult[]
    additionalPoints: {
        [Team.USA]: number,
        [Team.EUROPE]: number
    }
}

export type CompetitionEvent = {
    id: string;
    name: string
    year: number;
    course_name: string;
    matches: CompetitionMatch[]
}

export type Competition = {
    id: string;
    name: string;
    year: number;
    events: CompetitionEvent[];
};