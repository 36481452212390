import {
  alpha,
  badgeClasses,
  buttonBaseClasses,
  cardContentClasses,
  CSSInterpolation,
  darken,
  lighten,
  linkClasses,
  Palette,
  PaletteColor,
  PaletteMode,
  Theme,
  ThemeOptions
} from "@mui/material";
import {purple} from "@mui/material/colors";
import {createTheme} from "@mui/material/styles";
import {Shadows} from "@mui/material/styles/shadows";

interface CustomColours {
  background: React.CSSProperties["color"];
  paper: React.CSSProperties["color"];
  tableDetailPaper: React.CSSProperties["color"];
  paperWrapper: React.CSSProperties["color"];
  border: React.CSSProperties["color"];
}

declare module "@mui/material/styles" {
  interface Theme {
    customColours: CustomColours;
  }
  interface ThemeOptions {
    customColours: CustomColours;
  }
}

declare module "@mui/material/Paper" {
  interface PaperPropsVariantOverrides {
    "defect-list": true;
    "grouped-task": true;
    panel: true;
    breadcrumbs: true;
  }
}

declare module "@mui/material/Badge" {
  interface BadgePropsVariantOverrides {
    timeline: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    stat: true;
    "will-not-make-handover": true;
  }
}
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    invisible: true;
  }
}

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    header?: Palette["primary"];
    purple?: Palette["primary"];
  }
  interface PaletteOptions {
    header?: PaletteOptions["primary"];
    purple?: PaletteOptions["primary"];
  }
}

export type PaletteColour = "primary" | "info" | "success" | "error" | "warning";

const shadowIndex = 2;

const getDesignTokens = (mode: PaletteMode): ThemeOptions => ({
  spacing: 6,
  palette: {
    mode,
    primary: { main: "#556cd6" },
    secondary: { main: "#19857b" },
    purple: { main: purple[500] },
    header: { main: mode === "light" ? "white" : "#010409" }
  },
  customColours:
    mode === "light"
      ? {
          background: "#f4f4f4",
          paperWrapper: "#f9fafb",
          paper: "#FFFFFF",
          tableDetailPaper: "#f6f7f8",
          border: "rgba(0, 0, 0, 0.12)"
        }
      : {
          background: "#0d1117",
          paperWrapper: "#1c2229",
          paper: "#161b22",
          tableDetailPaper: "#090c10",
          border: "#30363d"
        },
  shadows: [
    "none",
    "0 1px 2px 0 rgba(0,0,0, 0.05)",
    "0 1px 3px 0 rgba(0,0,0,0.1), 0 1px 2px -1px rgba(0,0,0,0.1)",
    "0 4px 6px 0 rgba(0,0,0,0.1), 0 2px 4px -2px rgba(0,0,0,0.1)",
    ...Array(21).fill("none")
  ] as Shadows
});

export const getTheme = (mode: PaletteMode): Theme => {
  const isDarkMode = mode === "dark";

  const theme = createTheme(getDesignTokens(mode));

  const customPanelStyle: CSSInterpolation = {
    boxShadow: theme.shadows[shadowIndex],
    border: `1px solid ${isDarkMode ? theme.customColours.border : "transparent"}`,
    backgroundColor: theme.customColours.paper
  };

  return createTheme(theme, {
    palette: {
      background: {
        default: theme.customColours.background,
        paper: theme.customColours.paper
      }
    },
    components: {
      MuiTypography: {
        variants: [
          {
            props: { variant: "invisible" },
            style: {
              [`&.${linkClasses.root}`]: {
                textDecoration: "none",
                color: "inherit"
              }
            }
          }
        ]
      },
      MuiSwitch: {
        styleOverrides: {
          thumb: {
            boxShadow: theme.shadows[2]
          }
        }
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            borderBottom: `1px solid ${theme.customColours.border}`,
            "&:only-child": {
              borderBottom: "none"
            },
            padding: theme.spacing(2)
          },
          action: {
            margin: 0,
            [`& .${buttonBaseClasses.root}`]: {
              padding: theme.spacing(1),
              marginTop: theme.spacing(-1),
              marginBottom: theme.spacing(-1)
            }
          },
          title: {
            fontSize: ".95rem",
            fontWeight: 500,
            textTransform: "uppercase"
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderColor: theme.customColours.border
          }
        }
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            ...customPanelStyle,
            minHeight: "550px",
            height: "100%",
            margin: theme.spacing(0, 2, 2, 2)
          },
          withBorderColor: {
            borderColor: theme.customColours.border
          },
          detailPanel: {
            background: theme.customColours.tableDetailPaper
          },
          filterFormColumnInput: {
            width: "200px"
          }
        }
      },
      MuiCardActions: {
        defaultProps: {
          disableSpacing: true
        },
        styleOverrides: {
          root: {
            [`.${cardContentClasses.root} + &`]: {
              paddingTop: 0
            },
            padding: theme.spacing(2),
            gap: theme.spacing(2)
          }
        }
      },
      MuiChip: {
        variants: [
          ...["primary", "secondary", "success", "error", "warning", "info"].map(color => {
            const paletteColour = theme.palette[color as keyof Palette] as PaletteColor;
            return {
              props: { variant: "stat", color: color },
              style: {
                color: paletteColour.main,
                background: isDarkMode ? darken(paletteColour.light, 0.75) : lighten(paletteColour.light, 0.9),
                border: `1px solid ${alpha(paletteColour.main, isDarkMode ? 0.4 : 0.2)}`,
                padding: 0,
                fontWeight: 500,
                borderRadius: ".25rem",
                minWidth: "40px",
                ".MuiChip-label": {
                  paddingLeft: "4px",
                  paddingRight: "4px"
                }
              }
            };
          }),
          {
            props: { variant: "will-not-make-handover" },
            style: {
              padding: 0,
              fontWeight: 700,
              fontSize: theme.typography.body2.fontSize
            }
          }
        ]
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            "&:last-child": {
              paddingBottom: theme.spacing(2)
            }
          }
        }
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            "&:nth-last-of-type(1)": {
              borderBottom: "none"
            }
          }
        }
      },
      MuiBadge: {
        variants: [
          {
            props: { variant: "timeline" },
            style: {
              [`.${badgeClasses.badge}`]: {
                padding: "0",
                left: "9px",
                top: "-3px"
              }
            }
          }
        ]
      },
      MuiPaper: {
        defaultProps: {
          variant: "panel"
        },
        styleOverrides: { root: { backgroundImage: "unset" } },
        variants: [
          {
            props: { variant: "elevation" },
            style: {
              boxShadow: theme.shadows[shadowIndex]
            }
          },
          {
            props: { variant: "breadcrumbs" },
            style: {
              borderRadius: "initial",
              boxShadow: theme.shadows[shadowIndex],
              paddingLeft: theme.spacing(3),
              paddingRight: theme.spacing(3),
              paddingTop: theme.spacing(2),
              paddingBottom: theme.spacing(2),
              borderTop: `1px solid ${isDarkMode ? theme.customColours.border : "transparent"}`,
              borderBottom: `1px solid ${isDarkMode ? theme.customColours.border : "transparent"}`
            }
          },
          {
            props: { variant: "panel" },
            style: {
              ...customPanelStyle
            }
          },
          {
            props: { variant: "grouped-task" },
            style: {
              boxShadow: theme.shadows[shadowIndex],
              border: isDarkMode ? `1px solid ${theme.customColours.border}` : "none",
              background: theme.customColours.paperWrapper,
              [`& > .${cardContentClasses.root}`]: {
                padding: theme.spacing(2)
              }
            }
          },
          {
            props: { variant: "defect-list" },
            style: {
              boxShadow: theme.shadows[shadowIndex],
              [`& > .${cardContentClasses.root}`]: {
                padding: 0,
                "&:last-child": {
                  paddingBottom: 0
                }
              }
            }
          },
          {
            props: { variant: "task-wrapper" },
            style: {
              background: theme.customColours.paperWrapper,
              boxShadow: theme.shadows[shadowIndex]
            }
          }
        ]
      }
    }
  } as unknown as ThemeOptions);
};
