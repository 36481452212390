import {useEffect, useState} from "react";
import {isSignInWithEmailLink, sendSignInLinkToEmail, signInWithEmailLink, signInWithPopup} from "firebase/auth";
import {useNavigate} from "react-router-dom";
import {Alert, Box, Button, IconButton, InputAdornment, TextField, Typography} from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import SendIcon from "@mui/icons-material/Send";
import Spacer from "src/components/Spacer/Spacer";
import {auth, Providers} from "src/config/firebase";

interface Props {
}

const AuthContainer = (props: Props) => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [emailSent, setEmailSent] = useState(false)

    useEffect(() => {
        if (isSignInWithEmailLink(auth, window.location.href)) {
            // Additional state parameters can also be passed via URL.
            // This can be used to continue the user's intended action before triggering
            // the sign-in operation.
            // Get the email if available. This should be available if the user completes
            // the flow on the same device where they started it.
            let email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                // User opened the link on a different device. To prevent session fixation
                // attacks, ask the user to provide the associated email again. For example:
                email = window.prompt('Please provide your email for confirmation');
            }
            // The client SDK will parse the code from the link for you.
            // @ts-ignore
            signInWithEmailLink(auth, email, window.location.href)
                .then((result) => {
                    window.localStorage.removeItem('emailForSignIn');
                    setDisabled(false);
                    navigate({
                        pathname: '/',
                        search: '?',
                    });
                })
                .catch((error) => {
                    setErrorMessage(error.code + ": " + error.message);
                    setDisabled(false);
                });
        }
    }, []);

    const signInWithGoogle = () => {
        setDisabled(true);
        signInWithPopup(auth, Providers.google)
            .then(() => {
                setDisabled(false);
                navigate("/");
            })
            .catch((error) => {
                setErrorMessage(error.code + ": " + error.message);
                setDisabled(false);
            });
    };

    const signInWithEmail = (email: string) => {
        setDisabled(true);
        sendSignInLinkToEmail(auth, email, {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be in the authorized domains list in the Firebase Console.
            url: window.location.href,
            // This must be true.
            handleCodeInApp: true,
        })
            .then(() => {
                window.localStorage.setItem('emailForSignIn', email);
                setEmailSent(true);
            })
            .catch((error) => {
                setErrorMessage(error.code + ": " + error.message);
                setDisabled(false);
            });
    };

    const [email, setEmail] = useState<null | string>(null);

    return (
        <Box sx={{
            width: '90%',
            padding: 2,
            borderRadius: 1,
            border: '1px solid #c4c4c4'
        }}>
            <Spacer>
                <Spacer>
                    <TextField
                        variant={"outlined"}
                        label={"Email"}
                        size={"small"}
                        disabled={disabled}
                        onChange={event => setEmail(event.currentTarget.value)}
                        onKeyUp={(event) => {
                            if (event.code === 'Enter') {
                                if (email) signInWithEmail(email)
                            }
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton onClick={() => {
                                    if (email) signInWithEmail(email)
                                }}>
                                    <SendIcon/>
                                </IconButton>
                            </InputAdornment>,
                        }}
                    />
                    {emailSent && <Alert variant={"standard"}>
                        Email sent - check your inbox
                    </Alert>}
                </Spacer>

                <Typography component={"div"} sx={{
                    position: 'relative',
                }} textAlign={"center"}>
                    <Box sx={{
                        position: 'relative',
                        zIndex: '1',
                        background: '#f4f4f4',
                        width: '30px',
                        margin: '0 auto'
                    }}>OR</Box>
                    <Box sx={{
                        position: 'absolute',
                        background: '#c4c4c4',
                        top: '50%',
                        right: 0,
                        width: '100%',
                        height: '1px'
                    }}/>
                </Typography>

                <Button
                    startIcon={<GoogleIcon/>}
                    size="large"
                    fullWidth={true}
                    disabled={disabled}
                    variant="contained"
                    onClick={signInWithGoogle}
                >
                    SIGN IN WITH GOOGLE
                </Button>

                {errorMessage && <Typography sx={{mt: 2}} color={"red"}>
                    {errorMessage}
                </Typography>}
            </Spacer>
        </Box>
    );
};

export default AuthContainer;
