/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  clamp,
  filter as filterBy,
  find as findBy,
  first,
  groupBy,
  intersection,
  isEqual,
  last,
  omit,
  range,
  reverse,
  sortBy as autoSort,
  uniqBy,
  zip
} from "lodash";
import { prop, remove, sortBy, splitEvery, uniq, without } from "ramda";

type TypeGuard<Guard, Type> = Type extends Guard ? Type : never;

type TypeGuardInverted<Guard, Type> = Type extends Guard ? never : Type;

const toList = <TYPE>(list: TYPE[] = []): TYPE[] => list;
const filter = <TYPE>(list: TYPE[] = []) => ({
  by: (criteria: any) => filterBy(list, criteria)
});
const find = <TYPE>(list: TYPE[] = []) => ({
  by: (criteria: any) => findBy(list, criteria)
});
const isEmptyOrUndefined = <T>(list: T[] | undefined): boolean => !list || (list && list.length === 0);
const isNonEmptyArray = <T>(list: T[] | undefined): boolean => Array.isArray(list) && list.length > 0;
const isUndefined = <T>(value: T): value is TypeGuard<undefined, T> => value === undefined;
const isNotUndefined = <T>(value: T): value is TypeGuardInverted<undefined, T> => value !== undefined;
const unique = <T>(list: T[]): T[] => uniq(list);

const arraysShareItems = (arr1: any[], arr2: any[]): boolean => arr1.some(item => arr2.includes(item));

const sort = <TYPE>(list: TYPE[]) => ({
  byProp: (property: string): TYPE[] => sortBy(prop(property))(list)
});

interface sortByCreatedAt {
  createdAt: Date;
}

interface sortByCompletedAt {
  completedAt: Date;
}

const sortByNewest = <T extends sortByCreatedAt>(entities: T[]): T[] => {
  return [...entities].sort((entityA, entityB) => {
    return entityB.createdAt.getTime() - entityA.createdAt.getTime();
  });
};

const sortByOldest = <T extends sortByCreatedAt>(entities: T[]): T[] => {
  return sortByNewest(entities).reverse();
};

const sortByCompletedAt = <T extends sortByCompletedAt>(entities: T[], dir: "desc" | "asc" = "desc"): T[] => {
  const sorted = [...entities].sort((entityA, entityB) => {
    return entityB.completedAt.getTime() - entityA.completedAt.getTime();
  });
  return dir === "asc" ? sorted : sorted.reverse();
};

const toRecords = (list: string[] = []) => {
  return list.reduce<Record<string, string>>((records, value) => ({ ...records, [value]: value }), {});
};

type ObjectWithId<T> = {
  id: T;
};

const byId = <T>(id: T) => {
  return (object: ObjectWithId<T>): boolean => object.id === id;
};

const excludeId = <T>(id: T) => {
  return (object: ObjectWithId<T>): boolean => object.id !== id;
};

// items are the same, order is not necessarily the same
const isJaggedEqual = <T extends object | string>(array1: T[], array2: T[]) => {
  return isEqual(autoSort(array1), autoSort(array2));
};

const removeByIndex = <T>(items: T[], removalIndex: number): T[] => {
  return [...items.slice(0, removalIndex), ...items.slice(removalIndex + 1)];
};

const insertAtIndex = <T>(items: T[], insertionIndex: number, item: T): T[] => {
  return [...items.slice(0, insertionIndex), item, ...items.slice(insertionIndex)];
};

const reorder = <T>(items: T[], oldIndex: number, targetIndex: number) => {
  const itemToMove = items[oldIndex];
  return insertAtIndex(removeByIndex(items, oldIndex), targetIndex, itemToMove);
};

const sumBy =
  <T>(arr: T[]) =>
  (property: keyof T): number =>
    arr.reduce((sum, row) => sum + (row[property] as number), 0);

const averageBy =
  <T>(arr: T[]) =>
  (property: keyof T): number =>
    arr.reduce((sum, row) => sum + (row[property] as number), 0) / arr.length;

export {
  arraysShareItems,
  averageBy,
  byId,
  clamp,
  excludeId,
  filter,
  find,
  first,
  groupBy,
  insertAtIndex,
  intersection,
  isEmptyOrUndefined,
  isJaggedEqual,
  isNonEmptyArray,
  isNotUndefined,
  isUndefined,
  last,
  omit,
  range,
  remove,
  removeByIndex,
  reorder,
  reverse,
  sort,
  sortByCompletedAt,
  sortByNewest,
  sortByOldest,
  splitEvery,
  sumBy,
  toList,
  toRecords,
  uniqBy,
  unique,
  without,
  zip
};
